<template>
  <div class="app-nav__menu-item-wrapper">
    <div
      v-if="item.disabled"
      class="app-nav__menu-item app-nav__menu-item_disabled"
    >
      <span class="app-nav__menu-item-icon">
        <Component :is="item.icon" />
      </span>
      <span class="dropdown__menu-item-title">
        {{ item.title }}
      </span>
    </div>

    <a
      v-else-if="item.action"
      href="javascript: void(0)"
      class="app-nav__menu-item"
      @click.prevent.stop="item.action"
    >
      <span class="app-nav__menu-item-icon">
        <Component :is="item.icon" />
      </span>
      <span class="app-nav__menu-item-title">
        {{ item.title }}
      </span>
    </a>

    <router-link
      v-else
      :to="item.to"
      exact-active-class="app-nav__menu-item_active"
      class="app-nav__menu-item"
      exact
    >
      <span class="app-nav__menu-item-icon">
        <Component :is="item.icon" />
      </span>
      <span class="app-nav__menu-item-title">
        {{ item.title }}
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import VIcon from '@/components/ui/VIcon'
// import NavProjects from '@/components/nav/NavProjects'
import IconSettings from '@/assets/icons/settings2.svg'
import IconUsers from '@/assets/icons/users.svg'
import IconProjects from '@/assets/icons/projects.svg'
import IconCreditCard from '@/assets/icons/credit-card.svg'
import IconBulb from '@/assets/icons/bulb.svg'
import IconBell from '@/assets/icons/bell.svg'

export default {
  name: 'NavLink',
  components: {
    VIcon,
    // NavProjects,
    IconSettings,
    IconUsers,
    IconProjects,
    IconCreditCard,
    IconBulb,
    IconBell,
  },

  props: {
    item: Object,
  },
  computed: {
    ...mapState('general', [
      'openedAppNav',
    ]),
  },
}
</script>

<style lang="scss">
.app-nav {
  flex: none;
  background: #fff;
  border-right: 1px solid $borderColor;
  display: flex;
  flex-direction: column;
  width: 60px;
  overflow: hidden;
  transition: .3s ease-in-out;
  $root: &;

  @include dark-theme {
    background: $darkThemeBgThree;
    border-color: $darkThemeBorderDark;
  }

  &.opened {
    width: 274px;

    #{$root} {
      &__projects {
        span {
          opacity: 1;
        }
      }

      &__menu-item-title {
        opacity: 1;
      }
    }
  }

  &__projects {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-title {
      flex: none;
      display: flex;
      align-items: center;
      font-weight: 700;
      padding: 18px 19px 14px;
      white-space: nowrap;

      span {
        opacity: 0;
        transition: .3s ease-in-out;
      }

      svg {
        flex: none;
        margin-right: 9px;
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &-item {
      display: flex;
      align-items: center;
      color: $lightThemeText;
      font-size: 14px;
      font-weight: 300;
      line-height: 100%;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 11px 23px;
      transition: 0.2s ease-in-out;
      transition-property: background-color, opacity;

      @include dark-theme {
        color: $darkThemeTextLight;
      }

      &:hover {
        background-color: $lightThemeBackground2;

        @include dark-theme {
          background-color: $darkThemeBgOne;
        }
      }

      &_disabled, &_disabled:hover {
        background-color: transparent;
        opacity: 0.35;
        cursor: default;
      }

      &_active, &_active:hover {
        background: #DDDDE7;

        @include dark-theme {
          background-color: $darkThemeBgTwo;
        }

        svg path {
          fill: rgba(#202430, 0.5);

          @include dark-theme {
            fill: $darkThemeTextLight;
          }
        }
      }

      &-icon {
        margin-right: 23px;

        svg {
          width: 14px;
          height: 14px;
        }
      }

      &-title {
        opacity: 0;
        transition: 0.3s ease-in-out;
      }
    }

    &-separator {
      border-top: 1px solid $borderColor;
      margin: 10px 0;

      @include dark-theme {
        border-color: $darkThemeBorderDark;
      }
    }
  }
}
</style>
