<template>
  <div v-if="!meditationPage" :class="['app-nav', { 'opened': openedAppNav }]">
<!--    <div-->
<!--        v-if="-->
<!--        !showWorkspaceSettingsMenu-->
<!--        && !showProfileSettingsMenu-->
<!--        && !showSecuritySettingsMenu-->
<!--        && !showDisplaySettingsMenu-->
<!--      "-->
<!--        class="app-nav__projects"-->
<!--    >-->
<!--      <div class="app-nav__projects-title">-->
<!--        <VIcon name="Project" color="blue"/>-->
<!--        <span>{{ $t('Select project') }}</span>-->
<!--      </div>-->

<!--&lt;!&ndash;      <NavProjects/>&ndash;&gt;-->
<!--    </div>-->

    <div class="app-nav__menu">
      <template v-for="(item, index) in menuLinks">
        <div
            v-if="item.separator"
            class="app-nav__menu-separator"
            :key="index"
        ></div>

        <NavLink
            v-else
            :key="index"
            :item="item"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import VIcon from '@/components/ui/VIcon'
import NavLink from '@/components/nav/NavLink'

import IconSettings from '@/assets/icons/settings_gear.svg'
import IconNotification from '@/assets/icons/notification-on.svg'
import IconSecurity from '@/assets/icons/private.svg'
import IconDisplay from '@/assets/icons/group_settings.svg'
import IconBell from '@/assets/icons/bell.svg'
import IconWallet from '@/assets/icons/wallet.svg'
import IconGroup from '@/assets/icons/group.svg'
import IconAdvisors from '@/assets/icons/advisors.svg'

export default {
  name: 'TheNav',
  components: {
    VIcon,
    // NavProjects,
    NavLink,
  },

  computed: {
    ...mapState('general', [
      'openedAppNav',
    ]),

    ...mapGetters('workspace', [
      'getCurrentWorkspace',
    ]),

    showWorkspaceSettingsMenu () {
      return this.$route.name.includes('settings-workspace')
    },
    showProfileSettingsMenu () {
      return this.$route.path.includes('/setting')
    },

    meditationPage () {
      return this.$route.name.includes('meditation')
    },

    menuLinks () {
      if (this.showWorkspaceSettingsMenu) {
        return this.workspaceMenuLinks
      } else if (this.showProfileSettingsMenu) {
        return this.profileMenuLinks
      }

      return [
        { icon: IconWallet, title: this.$t('Robots'), to: { name: 'Robots' } },
        { icon: IconGroup, title: this.$t('Groups'), to: { name: 'Groups' } },
        { icon: IconAdvisors, title: this.$t('Advisors'), to: { name: 'Advisors' } },
      ].filter(({ hidden }) => !hidden)
    },
    workspaceMenuLinks () {
      return [
        { icon: IconSettings, title: this.$t('Profile'), to: { name: 'settings-profile' } },
        { icon: IconSecurity, title: this.$t('Security'), to: { name: 'settings-security' } },
        { icon: IconDisplay, title: this.$t('Display'), to: { name: 'settings-display' } }, // @WARNING: разкомментится, как будет готово
        { icon: IconBell, title: this.$t('Notification settings'), action: () => this.openNotificationsSettings() },
      ].filter(({ hidden }) => !hidden)
    },
    profileMenuLinks () {
      return [
        { icon: IconSettings, title: this.$t('Profile'), to: { name: 'settings-profile' } },
        { icon: IconNotification, title: this.$t('Notifications of scores'), to: { name: 'settings-notifications' } },
      ].filter(({ hidden }) => !hidden)
    },
  },

  created () {
    if (localStorage.openedAppNav) {
      this.toggleAppNav(JSON.parse(localStorage.openedAppNav))
    }
  },

  methods: {
    ...mapActions('general', [
      'toggleAppNav',
    ]),

    ...mapMutations('notification', [
      'TOGGLE_SETTINGS',
    ]),
    ...mapActions('notification', [
      'toggleOpened',
    ]),

    openNotificationsSettings () {
      this.toggleOpened(true)
      this.TOGGLE_SETTINGS(true)
    },
  },

  i18n: {
    messages: {
      'en': {
        Tasks: 'Tasks',
        Settings: 'Settings',
        'Select project': 'Select project',
        'Main settings': 'Main settings',
        Team: 'Team',
        Projects: 'Projects',
        Billing: 'Billing',
        Login: 'Login',
        Profile: 'Profile',
        Security: 'Security',
        Display: 'Display',
        'Notification settings': 'Notification settings',
      },
      'ru': {
        Tasks: 'Задачи',
        Settings: 'Настройки',
        'Select project': 'Выбор проекта',
        'Main settings': 'Настройки',
        Team: 'Команда',
        Projects: 'Проекты',
        Billing: 'Тарифы',
        Robots: 'Мои счета',
        Groups: 'Группы',
        Login: 'Вход',
        Profile: 'Профиль',
        Security: 'Безопасность',
        Display: 'Дисплей',
        'Notification settings': 'Настройки уведомлений',
        'Notifications of scores': 'Уведомления счетов',
      },
    },
  },
}
</script>

<style lang="scss">
.app-nav {
  flex: none;
  background: #fff;
  border-right: 1px solid $borderColor;
  display: flex;
  flex-direction: column;
  width: 60px;
  overflow: hidden;
  transition: .3s ease-in-out;
  $root: &;

  @include dark-theme {
    background: $darkThemeBgThree;
    border-color: $darkThemeBorderDark;
  }

  &.opened {
    width: 274px;

    #{$root} {
      &__projects {
        span {
          opacity: 1;
        }
      }

      &__menu-item-title {
        opacity: 1;
      }
    }
  }

  &__projects {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-title {
      flex: none;
      display: flex;
      align-items: center;
      font-weight: 700;
      padding: 18px 19px 14px;
      white-space: nowrap;

      span {
        opacity: 0;
        transition: .3s ease-in-out;
      }

      svg {
        flex: none;
        margin-right: 9px;
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &-item {
      display: flex;
      align-items: center;
      color: $lightThemeText;
      font-size: 14px;
      font-weight: 300;
      line-height: 100%;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 11px 23px;
      transition: 0.2s ease-in-out;
      transition-property: background-color, opacity;

      @include dark-theme {
        color: $darkThemeTextLight;
      }

      &:hover {
        background-color: $lightThemeBackground2;

        @include dark-theme {
          background-color: $darkThemeBgOne;
        }
      }

      &_disabled, &_disabled:hover {
        background-color: transparent;
        opacity: 0.35;
        cursor: default;
      }

      &_active, &_active:hover {
        background: #DDDDE7;

        @include dark-theme {
          background-color: $darkThemeBgTwo;
        }

        svg path {
          fill: rgba(#202430, 0.5);

          @include dark-theme {
            fill: $darkThemeTextLight;
          }
        }
      }

      &-icon {
        margin-right: 23px;

        svg {
          width: 14px;
          height: 14px;
        }
      }

      &-title {
        opacity: 0;
        transition: 0.3s ease-in-out;
      }
    }
  }
}
</style>
